// src/components/PuzzlePage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import PuzzleGame from './PuzzleGame';

// Import all puzzle images
import antonio from '../assets/rompecabezas/Antonio-pzl.jpg';
import atahualpa from '../assets/rompecabezas/Atahualpa-pzl.jpg';
import daquilema from '../assets/rompecabezas/Daquilema-pzl.jpg';
import dolores from '../assets/rompecabezas/Dolores-pzl.png';
import eloy from '../assets/rompecabezas/Eloy-pzl.jpg';
import eugenio from '../assets/rompecabezas/Eugenio-pzl.jpg';
import illescas from '../assets/rompecabezas/Illescas-pzl.jpg';
import juan from '../assets/rompecabezas/Juan-pzl.png';
import jumandy from '../assets/rompecabezas/Jumandy-pzl.png';
import manuelita from '../assets/rompecabezas/Manuelita-pzl.jpg';
import pintag from '../assets/rompecabezas/Pintag-pzl.jpg';
import quilago from '../assets/rompecabezas/Quilago-pzl.jpg';
import transito from '../assets/rompecabezas/Transito-pzl.png';

const puzzles = {
  antonio: antonio,
  atahualpa: atahualpa,
  daquilema: daquilema,
  dolores: dolores,
  eloy: eloy,
  eugenio: eugenio,
  illescas: illescas,
  juan: juan,
  jumandy: jumandy,
  manuelita: manuelita,
  pintag: pintag,
  quilago: quilago,
  transito: transito,
};

const PuzzlePage = () => {
  const { puzzleKey } = useParams();
  const puzzleImage = puzzles[puzzleKey];

  if (!puzzleImage) {
    return <div>Puzzle not found!</div>;
  }

  return (
    <PuzzleGame puzzleImage={puzzleImage} />
  );
};

export default PuzzlePage;
