import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="path/to/logo.png" alt="Logo" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" className="nav-item">Inicio</Link></li>
        <li><Link to="/personajes" className="nav-item">Personajes</Link></li>
        <li><Link to="/juegos" className="nav-item">Juegos</Link></li>
        <li><Link to="/videos" className="nav-item">Videos</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
