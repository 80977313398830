import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        &copy; {new Date().getFullYear()} <a href="https://ricoding.com/Shunku/Sinchiy1/" target="_blank" rel="noopener noreferrer">Shunku</a>. Todos los derechos reservados.
      </p>
    </footer>
  );
};

export default Footer;
