import React, { useState } from 'react';
import './MasPersonajes.css'; // Importar el nuevo archivo CSS
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

// Importar imágenes desde la carpeta de assets
import AntonioJoseDeSucreImage from '../assets/img/personajes/Antonio-Jose-de-Sucre2.png';
import DoloresCacuangoImage from '../assets/img/personajes/Dolores-Cacuango.png';
import EloyAlfaroImage from '../assets/img/personajes/Eloy-Alfaro.png';
import EugenioEspejoImage from '../assets/img/personajes/Eugenio-Espejo.png';
import FernandoDaquilemaImage from '../assets/img/personajes/Fernando-Daquilema.png';
import JuanMontalvoImage from '../assets/img/personajes/Juan-Montalvo.png';
import ManuelaEspejoImage from '../assets/img/personajes/Manuela-Espejo.png';
import ManuelitaSaenzImage from '../assets/img/personajes/Manuelita-Saenz.png';
import MarietaVeintimillaImage from '../assets/img/personajes/Marieta-Veintimilla.png';
import MejiaLequericaImage from '../assets/img/personajes/Mejia-Lequerica.png';
import TransitoAmaguanaImage from '../assets/img/personajes/Transito-Amaguana.png';
import PaperBackground from '../assets/img/paper.png';

// Importar imágenes de texto
import AntonioText from '../assets/img/textos/antonio-text.png';
import DaquilemaText from '../assets/img/textos/daquilema-text.png';
import JuanMontalvoText from '../assets/img/textos/juan-montalvo-text.png';
import MamaTransitoText from '../assets/img/textos/mama-transito-text.png';
import ManuelaEspejoText from '../assets/img/textos/manuela-espejo-text.png';
import MarietaText from '../assets/img/textos/marieta-text.png';
import MejiaText from '../assets/img/textos/mejia-text.png';
import SaenzText from '../assets/img/textos/saenz-text.png';

const nuevosPersonajesData = [
  {
    name: 'Antonio José de Sucre',
    image: AntonioJoseDeSucreImage,
    textImage: AntonioText,
    description: 'Antonio José de Sucre fue un líder militar y político venezolano, conocido como el Gran Mariscal de Ayacucho. Desempeñó un papel crucial en las guerras de independencia de América del Sur.',
  },
  {
    name: 'Dolores Cacuango',
    image: DoloresCacuangoImage,
    description: 'Dolores Cacuango fue una líder indígena ecuatoriana conocida por su lucha por los derechos de los pueblos indígenas y por la educación bilingüe.',
  },
  {
    name: 'Eloy Alfaro',
    image: EloyAlfaroImage,
    description: 'Eloy Alfaro fue un líder liberal ecuatoriano que sirvió como presidente de Ecuador. Fue conocido por sus reformas progresistas y su lucha por la modernización del país.',
  },
  {
    name: 'Eugenio Espejo',
    image: EugenioEspejoImage,
    description: 'Eugenio Espejo fue un intelectual, médico y escritor ecuatoriano, considerado uno de los precursores de la independencia de Ecuador.',
  },
  {
    name: 'Fernando Daquilema',
    image: FernandoDaquilemaImage,
    textImage: DaquilemaText,
    description: 'Fernando Daquilema fue un líder indígena ecuatoriano que se levantó contra las injusticias del sistema colonial español.',
  },
  {
    name: 'Juan Montalvo',
    image: JuanMontalvoImage,
    textImage: JuanMontalvoText,
    description: 'Juan Montalvo fue un destacado escritor y ensayista ecuatoriano, conocido por su lucha en favor de la libertad y la justicia.',
  },
  {
    name: 'Manuela Espejo',
    image: ManuelaEspejoImage,
    textImage: ManuelaEspejoText,
    description: 'Manuela Espejo fue una pionera en la lucha por los derechos de las mujeres y la igualdad en Ecuador.',
  },
  {
    name: 'Manuelita Sáenz',
    image: ManuelitaSaenzImage,
    textImage: SaenzText,
    description: 'Manuelita Sáenz fue una revolucionaria ecuatoriana que luchó por la independencia de América del Sur y fue una figura clave en la vida de Simón Bolívar.',
  },
  {
    name: 'Marieta Veintimilla',
    image: MarietaVeintimillaImage,
    textImage: MarietaText,
    description: 'Marieta Veintimilla fue una figura destacada en la sociedad ecuatoriana, conocida por su trabajo en favor de los derechos de las mujeres.',
  },
  {
    name: 'Mejía Lequerica',
    image: MejiaLequericaImage,
    textImage: MejiaText,
    description: 'Mejía Lequerica fue un importante político y orador ecuatoriano, conocido por su participación en la independencia de Ecuador.',
  },
  {
    name: 'Tránsito Amaguaña',
    image: TransitoAmaguanaImage,
    textImage: MamaTransitoText,
    description: 'Tránsito Amaguaña fue una líder indígena ecuatoriana, conocida por su lucha por los derechos de los pueblos indígenas y la reforma agraria.',
  },
];

const nuevosPersonajesConTexto = nuevosPersonajesData.filter(personaje => personaje.textImage);

const MasPersonajes = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const renderPersonajes = (start, end) => {
    return (
      <div className="d-flex justify-content-center">
        {nuevosPersonajesConTexto.slice(start, end).map((p) => (
          <div
            key={p.name}
            className="mas-character-card"
            onClick={() => setSelectedCharacter(p)}
          >
            <img src={p.image} alt={p.name} className="mas-character-image" />
            <img src={p.textImage} alt={`${p.name} text`} className="mas-character-text-image" />
            <p className="mas-character-name">{p.name}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="mas-personajes-container">
      {selectedCharacter ? (
        <div className="mas-character-detail">
          <img src={selectedCharacter.image} alt={selectedCharacter.name} />
          <div className="mas-text-description" style={{ backgroundImage: `url(${PaperBackground})` }}>
            <h1>{selectedCharacter.name}</h1>
            <p>{selectedCharacter.description}</p>
          </div>
          {selectedCharacter.textImage ? (
            <img src={selectedCharacter.textImage} alt={`${selectedCharacter.name} text`} className="mas-character-text-image" />
          ) : (
            <p className="error-text">Imagen de texto no disponible</p>
          )}
          <button onClick={() => setSelectedCharacter(null)}>Volver</button>
        </div>
      ) : (
        <>
          <Carousel interval={3000} indicators={false} controls={true}>
            {Array(Math.ceil(nuevosPersonajesConTexto.length / 6)).fill().map((_, i) => (
              <Carousel.Item key={i}>
                {renderPersonajes(i * 6, (i + 1) * 6)}
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="more-characters-link">
            <Link to="/">Volver a los Personajes</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default MasPersonajes;
