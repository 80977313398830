
// src/components/Sidebar.js
import React from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';

const Sidebar = ({ timer, score, onResetPuzzle, nextPuzzleLink }) => {
  return (
    <div className="sidebar">
      <h3>Game Menu</h3>
      <div className="sidebar-item">
        <label>Time:</label>
        <span>{timer}s</span>
      </div>
      <div className="sidebar-item">
        <label>Score:</label>
        <span>{score}</span>
      </div>
      <button className="reset-button" onClick={onResetPuzzle}>Reset Puzzle</button>
      <Link to={nextPuzzleLink} className="next-puzzle-button">Next Puzzle</Link>
    </div>
  );
};

export default Sidebar;
