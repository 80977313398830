// src/App.js
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Personajes from './components/Personajes';
import MasPersonajes from './components/MasPersonajes';
import Juegos from './components/Juegos';
import Videos from './components/Videos';
import Footer from './components/Footer';
import ImageSelection from './components/ImageSelection';
import PuzzlePage from './components/PuzzlePage';  // Import the new PuzzlePage component

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [selectedPuzzle, setSelectedPuzzle] = useState(null);

  const handleSelectPuzzle = (puzzleKey) => {
    setSelectedPuzzle(puzzleKey);
  };

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/personajes" element={<Personajes />} />
          <Route path="/mas-personajes" element={<MasPersonajes />} />
          <Route path="/juegos" element={<Juegos />} />
          <Route path="/image-selection" element={<ImageSelection onSelectPuzzle={handleSelectPuzzle} />} />
          <Route path="/puzzle/:puzzleKey" element={<PuzzlePage />} />  {/* New Route */}
          <Route path="/videos" element={<Videos />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
