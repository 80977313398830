import React, { useState, useEffect } from 'react';
import { createPuzzlePieces, shuffleArray } from '../utils/puzzleUtils';
import Sidebar from './Sidebar';
import './PuzzleGame.css';

const PuzzleGame = ({ puzzleImage, puzzleTitle, nextPuzzleLink }) => {
  const [pieces, setPieces] = useState([]);
  const [movesMade, setMovesMade] = useState(0);
  const [timer, setTimer] = useState(0);
  const [score, setScore] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = puzzleImage;

    image.onload = () => {
      const pieces = shuffleArray(createPuzzlePieces(image, 3, 4));
      setPieces(pieces);
    };
  }, [puzzleImage]);

  useEffect(() => {
    let interval;
    if (!isCompleted) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCompleted]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = e.dataTransfer.getData('text');
    swapPieces(sourceIndex, targetIndex);
    setMovesMade(movesMade + 1);
  };

  const swapPieces = (sourceIndex, targetIndex) => {
    const updatedPieces = [...pieces];
    [updatedPieces[sourceIndex], updatedPieces[targetIndex]] = [updatedPieces[targetIndex], updatedPieces[sourceIndex]];
    setPieces(updatedPieces);
    calculateScore(); // Update the score whenever pieces are swapped
  };

  const calculateScore = () => {
    // Basic scoring: decrease score with each move (you can customize this logic)
    setScore((prevScore) => prevScore + 10);
  };

  const checkWin = () => {
    return pieces.every((piece, index) => piece.correctIndex === index);
  };

  useEffect(() => {
    if (movesMade > 0 && checkWin()) {
      setIsCompleted(true);
      setTimeout(() => {
        const playerName = prompt('Congratulations! You have completed the puzzle! Enter your name:');
        saveScore(playerName, score, timer);
      }, 100);
    }
  }, [pieces, movesMade]);

  const saveScore = (name, score, time) => {
    const scores = JSON.parse(localStorage.getItem('puzzleScores')) || [];
    scores.push({ name, score, time });
    localStorage.setItem('puzzleScores', JSON.stringify(scores));
  };

  const handleResetPuzzle = () => {
    setPieces((prevPieces) => shuffleArray([...prevPieces]));
    setMovesMade(0);
    setScore(0);
    setTimer(0);
    setIsCompleted(false);
  };

  return (
    <div className="puzzle-game-container">
      <Sidebar timer={timer} score={score} onResetPuzzle={handleResetPuzzle} nextPuzzleLink={nextPuzzleLink} />
      <div className="puzzle-game">
        <h2 className="puzzle-title">{puzzleTitle}</h2>
        <div className="puzzle-thumbnail-container">
          <img src={puzzleImage} alt={puzzleTitle} className="puzzle-thumbnail" />
        </div>
        <div className="puzzle-container" style={{ width: `${448 * 4}px`, height: `${341.33 * 3}px` }}>
          {pieces.map((piece, index) => (
            <div
              key={index}
              className="puzzle-piece"
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
              style={{
                backgroundImage: `url(${piece.imgSrc})`,
                width: `${448}px`,
                height: `${341.33}px`,
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PuzzleGame;
