// src/utils/puzzleUtils.js

// Utility function to shuffle an array
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Update createPuzzlePieces to use shuffle
export const createPuzzlePieces = (image, rows = 3, cols = 4) => {
  const pieceWidth = image.width / cols;
  const pieceHeight = image.height / rows;
  const pieces = [];

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const canvas = document.createElement('canvas');
      canvas.width = pieceWidth;
      canvas.height = pieceHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        x * pieceWidth,
        y * pieceHeight,
        pieceWidth,
        pieceHeight,
        0,
        0,
        pieceWidth,
        pieceHeight
      );
      pieces.push({ x, y, imgSrc: canvas.toDataURL(), correctIndex: y * cols + x });
    }
  }

  return shuffleArray(pieces); // Shuffle the pieces before returning
};
