import React, { useState } from 'react';
import './Personajes.css';
import { Link } from 'react-router-dom';

// Importar imágenes desde la carpeta de assets
import PintagImage from '../assets/img/pintag.png';
import QuilagoImage from '../assets/img/quilago.png';
import AtahualpaImage from '../assets/img/atahualpa.png';
import RuminahuiImage from '../assets/img/ruminahui.png';
import JumandyImage from '../assets/img/jumandy.png';
import AntonImage from '../assets/img/anton.png';
import PaperBackground from '../assets/img/paper.png';

const personajesData = [
  {
    name: 'Pintag',
    image: PintagImage,
    description: 'Pintag fue un líder indígena ecuatoriano que defendió su tierra y su pueblo contra los invasores. Es conocido por su valentía y resistencia durante las guerras de resistencia contra los conquistadores españoles.',
  },
  {
    name: 'Quilago',
    image: QuilagoImage,
    description: 'Quilago fue una líder indígena ecuatoriana conocida por su valentía y habilidad en la batalla. Defendió su tierra y su gente contra los invasores, convirtiéndose en una figura legendaria en la historia de Ecuador.',
  },
  {
    name: 'Atahualpa',
    image: AtahualpaImage,
    description: 'Atahualpa fue el último emperador Inca que se encontró con los conquistadores españoles liderados por Francisco Pizarro. Fue capturado en la Batalla de Cajamarca y finalmente ejecutado por los españoles, marcando un momento crucial en la conquista española del Imperio Inca.',
  },
  {
    name: 'Rumiñahui',
    image: RuminahuiImage,
    description: 'Rumiñahui fue un líder y general inca que luchó valientemente contra los conquistadores españoles. Es conocido por su defensa de la ciudad de Quito y su resistencia implacable, convirtiéndose en un símbolo de la resistencia indígena.',
  },
  {
    name: 'Jumandy',
    image: JumandyImage,
    description: 'Jumandy fue un líder indígena de la Amazonía ecuatoriana que organizó una gran resistencia contra los conquistadores españoles. Es recordado por su valentía y por su determinación para defender su tierra y su gente.',
  },
  {
    name: 'Anton',
    image: AntonImage,
    description: 'Anton fue un destacado líder indígena conocido por su astucia y habilidades en la diplomacia. Jugó un papel crucial en las negociaciones entre tribus locales y los colonizadores, buscando siempre la paz y el bienestar de su pueblo.',
  },
];

const Personajes = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  return (
    <div className="personajes-container">
      {selectedCharacter ? (
        <div className="character-detail">
          <img src={selectedCharacter.image} alt={selectedCharacter.name} />
          <div className="text-description" style={{ backgroundImage: `url(${PaperBackground})` }}>
            <h1>{selectedCharacter.name}</h1>
            <p>{selectedCharacter.description}</p>
          </div>
          <button onClick={() => setSelectedCharacter(null)}>Volver</button>
        </div>
      ) : (
        <>
          {personajesData.map((personaje) => (
            <div
              key={personaje.name}
              className="character-card"
              onClick={() => setSelectedCharacter(personaje)}
            >
              <img src={personaje.image} alt={personaje.name} className="character-image" />
              <p className="character-name">{personaje.name}</p>
            </div>
          ))}
          <div className="more-characters-link">
            <Link to="/mas-personajes">Más Personajes</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Personajes;
