import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ImageSelection.css';

import antonio from '../assets/rompecabezas/Antonio-pzl.jpg';
import atahualpa from '../assets/rompecabezas/Atahualpa-pzl.jpg';
import daquilema from '../assets/rompecabezas/Daquilema-pzl.jpg';
import dolores from '../assets/rompecabezas/Dolores-pzl.png';
import eloy from '../assets/rompecabezas/Eloy-pzl.jpg';
import eugenio from '../assets/rompecabezas/Eugenio-pzl.jpg';
import illescas from '../assets/rompecabezas/Illescas-pzl.jpg';
import juan from '../assets/rompecabezas/Juan-pzl.png';
import jumandy from '../assets/rompecabezas/Jumandy-pzl.png';
import manuelita from '../assets/rompecabezas/Manuelita-pzl.jpg';
import pintag from '../assets/rompecabezas/Pintag-pzl.jpg';
import quilago from '../assets/rompecabezas/Quilago-pzl.jpg';
import transito from '../assets/rompecabezas/Transito-pzl.png';

const puzzles = {
  antonio: antonio,
  atahualpa: atahualpa,
  daquilema: daquilema,
  dolores: dolores,
  eloy: eloy,
  eugenio: eugenio,
  illescas: illescas,
  juan: juan,
  jumandy: jumandy,
  manuelita: manuelita,
  pintag: pintag,
  quilago: quilago,
  transito: transito,
};

const ImageSelection = ({ onSelectPuzzle }) => {
  const navigate = useNavigate();

  const handleClick = (puzzleKey) => {
    onSelectPuzzle(puzzleKey);
    navigate(`/puzzle/${puzzleKey}`);
  };

  return (
    <div className="image-selection-grid">
      {Object.keys(puzzles).map((puzzleKey) => (
        <div 
          key={puzzleKey} 
          className="image-selection-item" 
          onClick={() => handleClick(puzzleKey)}
        >
          <img src={puzzles[puzzleKey]} alt={puzzleKey} />
        </div>
      ))}
    </div>
  );
};

export default ImageSelection;